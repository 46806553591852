import React, {Component} from "react";
import axios from "../../api";
import * as Auth from "../../AuthService";
import {Button, Col, Row, Statistic, Tag} from "antd";

import {CheckOutlined, DownloadOutlined} from '@ant-design/icons';

import '../helpers.css'
import {downloadExcel, getColumnSearchProps} from "../../helpers";
import {DataCard} from "../helpers/cards/Card";
import {HelpModal} from "../helpers/modals/HelpModal";
import {properties} from "../../properties";
import {GetBack} from "../helpers/cards/MaterialBlock";
import {StandardTable} from "../helpers/TablePanel";

class IterationResultsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredInfo: {},
            sortedInfo: {},
            newPagination: {
                current: 1,
                pageSize: 10
            },
            total: 0,
            _filters: {}
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.records !== this.props.records && this.props.records) {
            this.setState({materialFilters: this.props.records.map(v => {return {text: v['material'], value: v['material']}}) })
        }
    }


    getDataSource = () => {
        const records = this.props.records;
        return records.map((record, i) => {
            return {
                ...record,
                // "material": <MaterialBlock material={record['material']} />,
                "new_min": <div style={{color: (record['new_min'] - record['rop'] > 0) ? 'red' : 'green'}}>{record['new_min']}</div>,
                "new_max": <div style={{color: (record['new_max'] - record['max'] > 0) ? 'red' : 'green'}}>{record['new_max']}</div>,
                "min_value_change_K": <div style={{color: (record['min_value_change_K'] < 0) ? 'red' : 'green'}}>{Math.round(record['min_value_change_K'] / 1000 * 100 ) / 100}</div>,
                "min_value_diff": record['min_value_change_K'],
                "max_value_diff": record['max_value_change_K'],
                "max_value_change_K": <div style={{color: (record['max_value_change_K'] < 0) ? 'red' : 'green'}}>{Math.round(record['max_value_change_K'] / 1000 * 100 ) / 100}</div>,
                'key': i,
                'price': <Statistic style={{marginTop: -15}} className='stat-price' value={record['price']}/>

            }
        })
    }

    state = {
        searchText: ""
    };

    handleSearch = (selectedKeys, dataIndex, confirm) => {
        confirm()
        this.setState({ searchText: selectedKeys[0] });
        this.props.handlePageChange({current: 1, pageSize: this.props.pagination.pageSize}, {'column': dataIndex, query: selectedKeys[0]}, this.props.sorter, this.props._filters)
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
        this.props.handlePageChange({current: 1, pageSize: this.props.pagination.pageSize}, {}, this.props.sorter, this.props._filters)
    };

    getColumns = () => {
        return [
            {
                title: 'Material',
                dataIndex: 'material',
                key: 'material',
                width: 100,
                ...getColumnSearchProps("material", true, this.state, this.handleReset, this.handleSearch),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                width: 200,
                ...getColumnSearchProps("description", false,  this.state, this.handleReset, this.handleSearch)
            },
            {
                title: 'Lead Time (days)',
                dataIndex: 'lt_days',
                key: 'lt_days',
            },
            {
                title: 'Number of BOMs',
                dataIndex: 'EQ BOM Count',
                key: 'EQ BOM Count',
            },
            {
                title: 'Annual Demand',
                dataIndex: 'demand',
                key: 'demand',
            },
            {
                title: 'Old MIN',
                dataIndex: 'min',
                key: 'min',
            },
            {
                title: 'New MIN',
                dataIndex: 'new_min',
                key: 'new_min',
            },
            {
                title: 'MIN Change',
                dataIndex: 'min_value_change_K',
                key: 'min_value_change_K',
                sorter: true,
            },
            {
                title: 'Old MAX',
                dataIndex: 'max',
                key: 'max',
            },
            {
                title: 'New MAX',
                dataIndex: 'new_max',
                key: 'new_max',
            },
            {
                title: 'MAX Change',
                dataIndex: 'max_value_change_K',
                key: 'max_value_change_K',
                sorter: true,
            },
            {
                title: 'Item Value',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Approved',
                dataIndex: 'approved',
                key: 'approved',
                filters: [
                    {value: true, text: 'Approved'},
                    {value: false, text: 'Not Approved'}
                ],
                render: approved => approved ? <CheckOutlined style={{color: "#107E70"}} /> : null
            },
        ];
    }

    check_Obj = (old, _new) => {
        return JSON.stringify(old) !== JSON.stringify(_new)
    }

    handleTableChange = (newPagination, _filters, sorter) => {
        if (this.check_Obj(newPagination, this.props.pagination) || this.check_Obj(sorter, this.props.sorter) || this.check_Obj(_filters, this.props._filters)){
            const pagination = {
                current: newPagination.current,
                pageSize: newPagination.pageSize
            }

            if (newPagination.pageSize !== this.props.pagination.pageSize) {
                pagination.current = 1
            }
            if (!sorter['order']) {
                sorter = {}
            }
            console.log(_filters)
            this.props.handlePageChange(pagination, this.props.search, sorter, _filters)
        }

    }

    render() {
        return (
            <div>
                <StandardTable data={this.getDataSource()}
                               scroll={700}
                               loading={this.props.loading}
                               total={this.props.total}
                               onChange={this.handleTableChange}
                               pageSize={this.props.pagination.pageSize}
                               currentPage={this.props.pagination.current}
                               tableProps={{
                                   rowClassName: (record) => record.approved ? 'approved' : ''
                               }}
                               columns={this.getColumns()} />
            </div>
        );
    }

}

export class IterationOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            projectId: props.location.pathname.split('/')[2],
            iterationInfo: {
                results: {
                    should_buy_beautified: '',
                    can_sell_beautified: ''
                },
                constants: {},
                scope: {

                }
            },
            _filters: {},
            helpModal: false,
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10
            },
            search: {},
            sorter: {}
        }
    }

    getIterationResults(pagination=this.state.pagination, _search=this.state.search, sorter=this.state.sorter, _filters= this.state._filters) {
        if (!this.state.loading) {
            const project_id = this.props.location.pathname.split('/')[2]
            const iteration_id = this.props.location.pathname.split('/')[5]
            this.setState({loading: true})
            axios.post(`/projects/${project_id}/iterations/${iteration_id}/min-max?page=${pagination.current}&size=${pagination.pageSize}`, {
                _search,
                _filters,
                sorter
            }, Auth.createConfig())
                .then(json => {
                    this.setState({
                        records: json.data.items,
                        total: json.data.total,
                        pagination,
                        _filters,
                        sorter,
                        search: _search
                    })

                }).catch(error => {
                console.log(error)
            }).finally(() => this.setState({loading: false}))
        }
    }

    componentDidMount() {
        const project_id = this.props.location.pathname.split('/')[2]
        const iteration_id = this.props.location.pathname.split('/')[5]
        axios.get(`/projects/${project_id}/iteration/${iteration_id}/overview`, Auth.createConfig())
            .then(json_results => {
                this.setState({iterationInfo: json_results.data})
            }).catch(error => {
            console.log(error)
        })
        this.getIterationResults()
    }

    exportIteration = () => {
        const project_id = this.props.location.pathname.split('/')[2]
        const iteration_id = this.props.location.pathname.split('/')[5]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${project_id}/iterations/${iteration_id}/min-max/export`, config)
            .then(json => {
                downloadExcel(json.data, `Proposed MIN-MAX ${this.state.iterationInfo.iteration_name}.xlsx`)
            }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
            <div style={{minHeight: '90vh', height: 'max-content'}}>
            <Row>
                <Col span={3} style={{padding: 15}}>
                    <GetBack link={`/projects/${this.state.projectId}/min-max`} />
                </Col>
                <Col span={18}>
                <DataCard title={
                    <div className='text-center'
                         style={{fontSize: 24}}>
                        {this.state.iterationInfo.iteration_name} {this.state.iterationInfo.scope.label && `- [ ${this.state.iterationInfo.scope.label} ]`}
                        {this.state.iterationInfo.iteration_name && <DownloadOutlined style={{marginLeft: 20, color: "#107E70"}} onClick={this.exportIteration}/>}
                        <Button className='float-right' style={{marginLeft: 10}} onClick={() => this.setState({helpModal: true})}>?</Button>
                    </div>}
                      style={{margin: 10}}>
                    <Tag color={properties.kbrColors.pantone301Blue}>Scenario Summary</Tag>
                    <Row>
                        <Col span={8}>
                            <Statistic className='text-center' title='Materials Analyzed' value={this.state.iterationInfo.results.items_affected}/>
                        </Col>
                        <Col span={8}>
                            <Statistic className={`text-center ${this.state.iterationInfo.results.should_buy_beautified.includes('-') ? 'stat-negative-value' : 'stat-positive-value'}`}
                                       title='Recommended Buy'
                                       value={this.state.iterationInfo.results.should_buy_beautified}/>
                        </Col>
                        <Col span={8}>
                            <Statistic className={`text-center ${this.state.iterationInfo.results.can_sell_beautified.includes('-') ? 'stat-negative-value' : 'stat-positive-value'}`}
                                       title='Recommended Sell'
                                       value={this.state.iterationInfo.results.can_sell_beautified}/>
                        </Col>
                    </Row>
                    <Tag color={properties.kbrColors.pantone301Blue}>Assumptions</Tag>
                    <Row>
                        <Col span={5}>
                            <Statistic className='text-center' title='Customer Service Level' value={this.state.iterationInfo.constants.service_level } suffix={this.state.iterationInfo.constants.csf && `(CSF: ${this.state.iterationInfo.constants.csf})`}/>
                        </Col>
                        <Col span={5}>
                            <Statistic className='text-center' title='Cost to place one order' value={this.state.iterationInfo.constants.s}/>
                        </Col>
                        <Col span={5}>
                            <Statistic className='text-center' title='Default price per line' value={this.state.iterationInfo.constants.default_price}/>
                        </Col>
                        <Col span={5}>
                            <Statistic className='text-center' title='Mean Absolute Deviation' value={this.state.iterationInfo.constants.mad}/>
                        </Col>
                        <Col span={4}>
                            <Statistic className='text-center' title='Holding Cost' value={this.state.iterationInfo.constants.holding && this.state.iterationInfo.constants.holding * 100} suffix={'%'}/>
                        </Col>
                    </Row>
                </DataCard>
                </Col>
                <Col span={3}/>
            </Row>
                <div style={{margin: 10}}>

                    <IterationResultsTable loading={this.state.loading}
                                           records={this.state.records}
                                           total={this.state.total}
                                           currentPage={this.state.currentPage}
                                           size={this.state.size}
                                           pagination={this.state.pagination}
                                           sorter={this.state.sorter}
                                           _filters={this.state._filters}
                                           search={this.state.search}
                                           handlePageChange={(pagination, search, sorter, filters) => this.getIterationResults(pagination, search, sorter, filters)} />
                </div>
                <HelpModal isModalVisible={this.state.helpModal} setModalVisible={(value) => this.setState({helpModal: value})} />

            </div>
        );
    }

}

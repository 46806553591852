import React, {useEffect, useState} from "react";
import {Checkbox, Form, InputNumber} from "antd";
import {ModalWindow} from "./ModalWindow";
import TextArea from "antd/es/input/TextArea";

export function EditingMaterialModal({item, save, isModalVisible, closeModal, canApprove}) {

    const [approved, setApproved] = useState(false)

    const [form] = Form.useForm();

    const handleCancel = () => {
        closeModal();
    };

    const onFinish = (values) => {
        save({...values, approved: approved}, form)
    };

    const handleCheckBoxChange = value => {
        setApproved(value)
    }

    useEffect(() => {
        form.resetFields()
        setApproved(item.approved === 'NA' ? false : item.approved)
    }, [isModalVisible])



    const initialValues = {
        ...item,
        comment: '',
        appr_min: item.approved ? item.new_min : 0,
        appr_max: item.approved ? item.new_max : 0
    }


    return (
        <ModalWindow width={900} title={`Editing Material ${item.material} Parameters`}
                     isModalVisible={isModalVisible}
                     scrollVisible={false}
                     onCancel={handleCancel}
                     modalProps={{
                         okText: "Update",
                         cancelText: "Cancel",
                         okButtonProps: {form: 'materialEditForm', key: 'submit', htmlType: 'submit'}
                     }}>
            <Form
                onFinish={onFinish}
                form={form}
                initialValues={initialValues}
                id="materialEditForm"
            >
                <Form.Item
                    label="Lead Time (days)"
                    name="lt_days"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the scenario name!',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    label="Annual Demand"
                    name="demand"

                    rules={[
                        {
                            required: true,
                            message: 'Please input the Annual Demand!',
                        },
                    ]}
                >
                    <InputNumber width={'500px'} min={0}/>
                </Form.Item>

                <Form.Item
                    label="Price per unit"
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the service level!',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>


                <Form.Item
                    label="Comment"
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the comment!',
                        },
                    ]}
                >
                    <TextArea/>
                </Form.Item>
                {canApprove && <Form.Item
                    label="Override MIN/MAX manually"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Checkbox value={approved || false} checked={approved}
                              onChange={(event) => handleCheckBoxChange(event.target.checked)}/>
                </Form.Item>}
                {(canApprove && approved) && <Form.Item
                    disabled={approved}
                    label="New Min"
                    name="appr_min"
                    rules={[
                        {
                            required: approved,
                        },
                    ]}
                >
                    <InputNumber disabled={!approved}/>
                </Form.Item>}
                {(canApprove && approved) && <Form.Item
                    label="New Max"
                    name="appr_max"
                    rules={[
                        {
                            required: approved,
                        },
                    ]}
                >
                    <InputNumber disabled={!approved}/>
                </Form.Item>}
            </Form>
        </ModalWindow>
    );
}

import React, {useEffect, useState} from "react";
import api from "../../api";
import {useForm} from "antd/es/form/Form";
import {Button, Popover, Tabs} from "antd";
import {StandardForm} from "./forms/StandardForm";

const {TabPane} = Tabs;

export function AdvancedFilter({projectId, setFilter, filters, labels={}}) {

    const [attributes, setAttributes] = useState([])

    const [selected, setSelected] = useState({})
    const [proposedValues, setProposedValues] = useState([])

    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    useEffect(() => {
        api.get(`/projects/${projectId}/materials/attributes`)
            .then(json => setAttributes(json.data))
    }, [])


    const createAttributes = () => {
        return attributes.map(a => {
            return {
                value: a.name,
                label: labels[a.name] || a.name
            }
        }).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    }

    const AttributesFields = [
        {label: 'Attribute Name', name: 'name', _type: 'select-object', message: 'Error', required: true, options: createAttributes()},
    ]

    const addFilter = (values) => {
        setFilter({...filters, [values['name']]: {value: values['value'], 'mode': values['mode'], type: selected.type}})
        setOpen(false)
        attrNameForm.resetFields(['name', 'value'])
    }

    const [attrNameForm] = useForm()

    const [attrValueForm] = useForm()

    const [attrModeForm] = useForm()

    useEffect(() => {
        if (selected && selected.name) {
            getProposedValues()
            attrModeForm.resetFields(['mode'])
            attrValueForm.resetFields(['value'])
        }
    }, [selected])


    useEffect(() => {
        if (open) {
            setSelected({})
        }
        }, [open]
    )

    const onFieldsChange = (fields) => {
        if (fields[0].name[0] === 'name') {
                setSelected(attributes.find(x => x['name'] === fields[0].value))
        }
    }

    const stringOptions = [
        {
            value: 'full',
            label: 'Full Search'
        }, {
            value: 'partial',
            label:'Partial Search'
        }
    ];

    const numberOptions = [
        {
            value: 'gt',
            label: 'Greater than'
        }, {
            value: 'eq',
            label: 'Equal to'
        }, {
            value: 'lt',
            label:'Less than'
        }
    ]

    const getOptionsByType = () => {
        if (selected.type === 'text') {
            return stringOptions
        } else if (selected.type === 'number') {
            return numberOptions
        }
        return []
    }

    const getProposedValues = () => {
        api.get(`/projects/${projectId}/materials/attributes/values?attr=${selected.name}`)
            .then(json => {
                setProposedValues(json.data)
            })
    }

    const getProposedOptions = () => {
        return proposedValues
    }

    const getModeValue = () => {
        return [{label: 'Search mode', name: 'mode', _type: 'select-object', message: 'Error', required: true, options: getOptionsByType()}]
    }

    const getValuesFields = () => {
        return [{label: 'Attribute Value', name: 'value', _type: 'autocomplete', message: 'Error', required: true, options: getProposedOptions()}]
    }

    const addFilterFull = () => {
        const result = {
            name: attrNameForm.getFieldValue("name"),
            value: attrValueForm.getFieldValue("value"),
            mode: attrModeForm.getFieldValue("mode")
        }
        addFilter(result)
    }

    const content = <div style={{width: 350}}>
        <Tabs defaultActiveKey={"1"}>
            <TabPane tab={'Attribute'} key={"1"}>
                <StandardForm onFieldsChange={onFieldsChange} form={attrNameForm} fields={AttributesFields} onFinish={addFilter}/>

                {selected && selected.name && <StandardForm form={attrValueForm} fields={getValuesFields()} onFinish={() => console.log()}/>}


                {selected && selected.name && <StandardForm form={attrModeForm} fields={getModeValue()} onFinish={() => console.log()}/>}

                <Button className={'submit-button'} style={{float: 'right'}}
                        onClick={() => addFilterFull()}>Submit</Button>
            </TabPane>
        </Tabs>
    </div>

    return <Popover open={open} onOpenChange={handleOpenChange} content={content} title="Advanced Filter"
                    trigger="click">
        <Button onClick={() => setOpen(true)}
                size="small"
                icon={<img src={process.env.PUBLIC_URL + '/images/filter_list-24px.svg'}/>}

        >
            Add Filter
        </Button></Popover>

}

import {loadUIConfig} from "@digatex/digatex-ui-lib";
import PicklesConfig from './config/ui-config-pickles.json'
import PTTConfig from './config/ui-config-ptt.json'

export const properties = {
    apiUrl: process.env.REACT_APP_HOST,
    ihLink: process.env.REACT_APP_IH_LINK,
    dmLink: process.env.REACT_APP_DM_LINK,
    khLink: process.env.REACT_APP_KH_LINK,
    reportingLink: process.env.REACT_APP_REPORTING_LINK,
    analyticsLink: process.env.REACT_APP_WB_LINK,
    assetHubLink: process.env.REACT_APP_EH_LINK,
    version: process.env.REACT_APP_WB_VERSION,
    landingHeaderName: process.env.REACT_APP_LANDING_NAME || 'SIMS Platform',

    kbrColors: {
        pantone301Blue: "#004987",
        lightBlue: "#A2C7E2",
        yellow: "#ffda00",
        dark: "#00205C",
        green: "#00783F",
        black:"#231f20",
        vividCeruleanBlue: "#007fc5",
        acajouMarron: "#4c212a"

    },

    startColors: [
        "#007fc5",
        "#A2C7E2",
    ],

    targetColors: [
        "#007fc5",
        "#A2C7E2",
    ],

    currentColors: [
        "#007fc5",
        "#A2C7E2",
    ]
};

export const KEYCLOAK_AUTH_SERVER_URL = process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL
export const OWNER = process.env.REACT_APP_CLIENT_NAME

export const DEFAULT_OPERATOR = process.env.REACT_APP_DEFAULT_OPERATOR

export let UI_CONFIG;

if (['kbr', 'digatex'].includes(OWNER)) {
    UI_CONFIG = loadUIConfig()
    UI_CONFIG.header.logo.title = 'Workbench'
} else {
    switch (OWNER) {
        case 'pickles': {
            UI_CONFIG = PicklesConfig;
            UI_CONFIG.header.logo.version = process.env.REACT_APP_PROJECT_VERSION
            break;
        }
        case 'ptt': {
            UI_CONFIG = PTTConfig;
            UI_CONFIG.header.logo.version = process.env.REACT_APP_PROJECT_VERSION
            break;
        }
        default:
            console.error('Failed to load UI config: the client is unknown');

    }
}

import React from 'react';
import {DataCard} from "../helpers/cards/Card";
import api from "../../api";
import {Col, Row} from "antd";
import {Statistic} from "antd/es";
import {StandardTable} from "../helpers/TablePanel";
import {GetBack} from "../helpers/cards/MaterialBlock";


export class ScopePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scopeId: window.location.pathname.split('/')[4],
            projectId: window.location.pathname.split('/')[2],
            scopeInfo: {},
            overview: {},
            page: 1,
            size: 10,
            total: 0,
            loading: false,
            scopeData: []
        }
    }

    getScopeInfo = () => {
        api.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}`)
            .then(json => this.setState({scopeInfo: json.data}))
    }

    getScopeOverview = () => {
        api.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}/overview`)
            .then(json => this.setState({overview: json.data}))
    }

    getScopeItems = (page, size) => {
        this.setState({loading: true})
        if (size && size !== this.state.size) {
            page = 1
        }
        api.get(`/projects/${this.state.projectId}/scope/${this.state.scopeId}/list?page=${page || this.state.page}&size=${size || this.state.size}`)
            .then(json => {
                this.setState({scopeData: json.data.items, total: json.data.total})
                if (page) {
                    this.setState({page: page})
                }
                if (size) {
                    this.setState({size})
                }
            })
            .finally(() => this.setState({loading: false}))
    }

    componentDidMount() {
        console.log(window.location)
        this.getScopeInfo();
        this.getScopeOverview()
        this.getScopeItems()
    }

    getColumns = () => [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'name',
            width: '8%'
        }, {
            title: 'Material Type',
            dataIndex: 'Type',
            key: 'type',
            width: '8%'

        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '50%'

        }, {
            title: 'Criticality',
            dataIndex: 'criticality',
            key: 'criticality',
        }, {
            title: 'Demand',
            dataIndex: 'demand',
            key: 'demand',
        }, {
            title: 'Lead Time',
            dataIndex: 'lt_days',
            key: 'lt_days',
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        }
    ]

    onPageChange = (newPagination, _filters, _sorter) => {
        console.log(newPagination)
        this.getScopeItems(newPagination.current, newPagination.pageSize)
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={1} style={{padding: 5}}>
                        <GetBack link={`/projects/${this.state.projectId}/scopes-settings`} />

                    </Col>
                    <Col span={11}>
                        <DataCard style={{margin: 5}} title={<div className='text-center'> Scope <b>{this.state.scopeInfo.name}</b> - {this.state.scopeInfo.description}</div>}>
                            <Row>
                                <Col span={8}>
                                    <Statistic title={'Items'} value={this.state.overview.quantity} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title={'Stock'} value={this.state.overview.stock} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title={'Value'} value={this.state.overview.value} suffix={'m'} />
                                </Col>
                            </Row>
                        </DataCard>
                    </Col>
                    <Col span={1} />
                    {/*<Col span={11}>*/}
                    {/*    <DataCard style={{margin: 5}} title={<div className='text-center'> Scope <b>{this.state.scopeInfo.name}</b> - {this.state.scopeInfo.description}</div>}>*/}
                    {/*        <Row>*/}
                    {/*            <Col span={8}>*/}
                    {/*                <Statistic title={'Items'} value={this.state.overview.quantity} />*/}
                    {/*            </Col>*/}
                    {/*            <Col span={8}>*/}
                    {/*                <Statistic title={'Stock'} value={this.state.overview.stock} />*/}
                    {/*            </Col>*/}
                    {/*            <Col span={8}>*/}
                    {/*                <Statistic title={'Value'} value={this.state.overview.value} suffix={'m'} />*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </DataCard>*/}
                    {/*</Col>*/}
                </Row>

                <DataCard style={{margin: 5}}>
                    <StandardTable size={'small'}
                                   loading={this.state.loading}
                                   style={{width: '100%'}}
                                   total={this.state.total}
                                   currentPage={this.state.page}
                                   columns={this.getColumns()}
                                   scroll={500}
                                   onChange={this.onPageChange}
                                   pageSize={this.state.size}
                                   data={this.state.scopeData}/>
                </DataCard>
            </>
        );
    }
}

import React from "react";
import {DataCard} from "./cards/Card";
import {Col, Row, Statistic} from "antd";

export class InfoPanel extends React.Component {

    getBackgroundColor() {
        const title = this.props.title;
        if (this.props.searched) {
            return 'rgba(0,120,63,0.2)'
        }
        return title===this.props.selected ? 'rgba(162,199,226,0.2)' : ''
    }

    render() {

        const info = this.props.info;
        const title = this.props.title;

        return (
            <DataCard title={<div className='text-center' style={{fontSize: 18}}>{title}</div>} style={{margin: 15, backgroundColor: this.getBackgroundColor()}}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Statistic title='Quantity' value={info.quantity}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Statistic title='Stock' value={info.stock}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <Statistic title='Value' value={info.value}/>
                    </Col>
                </Row>
            </DataCard>
        );
    }

}

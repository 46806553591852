import api from "../../api";
import {message} from "antd";
import {StandardModalForm} from "../helpers/modals/StandardModalForm";
import React from "react";

export function AddScopeModal({isModalVisible, setIsModalVisible, projectId, isSuperAdmin}) {

    const closeEditModal = () => {
        setIsModalVisible(false)
    }

    const saveEditValues = (values) => {
        if (!Object.keys(values).includes('type')) {
            values['type'] = 'user-defined'
        }
        api.post(`/projects/${projectId}/scopes/add`, values)
            .then(() => {
                message.success(`The scope ${values.name} was successfully created`)
                setIsModalVisible(false)
            })
            .catch(() => message.error('Something went wrong during saving! Please try again later.'))
    }

    const fields = [
        {label: 'Scope Name', name: 'name', _type: 'string', message: 'Error', required: true},
        {label: 'Scope Description', name: 'description', _type: 'long_text', message: 'Error', required: true},
    ]

    if (isSuperAdmin) {
        fields.push({
                label: 'Scope Type',
                name: 'type',
                _type: 'select',
                message: 'Error',
                required: true,
                options: ['main-scope', 'user-defined']
            },
        )
    }

    return <StandardModalForm fields={fields} isModalVisible={isModalVisible} closeModal={closeEditModal}
                              initialValues={{}} save={saveEditValues}/>

}

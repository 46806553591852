import React, {Component} from "react";
import {MaterialBlock} from "./helpers/cards/MaterialBlock";
import Highlighter from "react-highlight-words";
import {properties} from "../properties";
import {openLinkInNewTab} from "../helpers";
import {Link} from "react-router-dom";
import {StandardTable} from "./helpers/TablePanel";
import api from "../api";
import {DataCard} from "./helpers/cards/Card";
import {Button, Col, Descriptions, Input, Row} from "antd";


export class ListOfMaterials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            query: '',
            prevQuery: '',
            total: 0,
            page: 1,
            filters: {},
            size: 50,
            results: [],
            loading: false
        }
    }

    componentDidMount() {
        let p = window.location.search;
        const params = new URLSearchParams(p);
        const search = params.get('search') || ''
        this.search(null, search)
    }

    search = (event, query = this.state.value, page = this.state.page, size=this.state.size, filters = this.state.filters) => {
        event && event.preventDefault();
        this.setState({loading: true})
        if (query !== this.state.prevQuery || size !== this.state.size) {
            page = 1
        }
        api.post(`${this.props.baseUrl}?query=${query}&page=${page}&step=${size}`, {filters: filters} )
            .then(json => this.setState({results: json.data.results, query: query, prevQuery: query, total: json.data.total, page: page, size: size, filters: filters}))
            .finally(() => this.setState({loading: false}))
    }

    onChange = (newPagination, _filters, _sorter) => {
        this.search(null, this.state.value, newPagination.current, newPagination.pageSize, _filters)
    }

    columns = () => {
        const from = `${window.location.href}?search=${this.state.query}`
        return [
            {
                title: 'Material',
                dataIndex: 'Material',
                key: 'Material',
                render: text => <MaterialBlock material={text}><Highlighter
                    style={{color: properties.kbrColors.pantone301Blue, cursor:'pointer'}}
                    onClick={() => openLinkInNewTab(this.props.createLink(text, from))}
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.query]}
                    autoEscape
                    textToHighlight={text ? text.toString(): null}
                /></MaterialBlock>
            },
            {
                title: 'Material Description',
                dataIndex: 'Material Description',
                key: 'Material Description',
                render: text => <Highlighter
                    onClick={() => openLinkInNewTab(this.props.createLink(text, from))}
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.props.query]}
                    autoEscape
                    textToHighlight={text ? text.toString(): null}
                />
            },
            {
                title: 'Type',
                dataIndex: 'Type',
                key: 'Type',
                filters: [
                    {
                        text: 'High Turnover',
                        value: 'High Turnover',
                    },
                    {
                        text: 'Low Turnover',
                        value: 'Low Turnover',
                    },
                    {
                        text: 'Slow Moving',
                        value: 'Slow Moving',
                    },
                ]
            },
            {
                title: 'Open',
                dataIndex: 'Material',
                key: 'Material',
                render: material => {
                    return <div className={'beautiful-link'}
                                onClick={() => openLinkInNewTab(this.props.createLink(material, from))}>
                        Open
                    </div>
                }
            },

        ]
    }

    render() {

        const style = {
            height: '80vh', maxHeight: '80vh'
        }

        return (
            <>
                <DataCard style={style}>
                <Row style={{padding: 10}}>
                    <Col span={4} />
                    <Col span={16}>
                        <Input.Group compact>
                            <Input style={{ width: 'calc(100% - 200px)' }}
                                   value={this.state.value}
                                   onChange={event => this.setState({value: event.target.value})}
                                   onPressEnter={this.search}
                                   placeholder='Input the material' />
                            <Button style={{backgroundColor: properties.kbrColors.pantone301Blue, color: "white"}}
                                    onClick={this.search}>
                                Submit
                            </Button>
                        </Input.Group>
                    </Col>
                    <Col span={4} />
                </Row>
                <Row style={{padding: 10}}>

                    {this.state.query && <Descriptions>
                        <Descriptions.Item label="Search Query">{this.state.query}</Descriptions.Item>
                    </Descriptions>}
                    <StandardTable size={'small'}
                                   loading={this.state.loading}
                                   style={{width: '100%'}}
                                   total={this.state.total}
                                   currentPage={this.state.page}
                                   columns={this.columns()}
                                   scroll={500}
                                   onChange={this.onChange}
                                   pageSize={this.state.size}
                                   data={this.state.results}  />
                </Row>
            </DataCard>


                </>

        );
    }

}

import React, {Component} from "react";
import {Form, Input, Modal, Select} from "antd";
import axios from "../../api";
import * as Auth from '../../AuthService'
import {Option} from "antd/es/mentions";

export class MinMaxModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {
                constants: {
                    service_factor_table: []
                }
            },
            scopes: []
        }
    }

    form = React.createRef();

    handleCancel = () => {
        this.props.closeModal();
    };
    onFinish = (values) => {
        const constants = this.state.settings.constants;
        const data = {
            iteration_name: values.name,
            scope: this.props.scopes[values.scope],
            constants: {
                csf: constants.service_factor_table[values.csf].csf,
                service_level: constants.service_factor_table[values.csf].sl,
                mad: parseFloat(values.mad),
                holding: parseFloat(values.holding) / 100,
                s: parseFloat(values.s),
                default_price: constants.default_price
            }
        }
        axios.post(`/projects/${this.props.projectId}/iteration/add`, data, Auth.createConfig())
            .then(() => {
                alert('Iteration was created successfully!')
                this.props.closeModal()
            })
            .catch(error => {
                alert('Something went wrong. Try it out later')
                console.log(error)
            })
    };

    componentDidUpdate(prevProps) {
        if (prevProps.projectId !== this.props.projectId && this.props.projectId) {
            axios.get(`/projects/${this.props.projectId}/settings`, Auth.createConfig()).then(json => this.setState({settings: json.data}))
        }
    }

    getTheIndexOfDefault() {
        const constants = this.state.settings.constants;
        const _default = constants.default_service_level;
        return constants.service_factor_table.findIndex(element => element.sl === _default.sl && element.csf === _default.csf)
    }

    render() {

        const constants = this.state.settings.constants;
        const options = constants && constants.service_factor_table.map((v,i) => <Option key={i}
                                                                                         value={i}>Service Level: {v.sl} - Customer Service Factor: {v.csf}
        </Option>)

        const scopeOptions = this.props.scopes && this.props.scopes.map((v,i) => <Option key={i} value={i}>{v.name} ({v.quantity || 0} items)</Option>)

        const initialValues = {
            csf: this.getTheIndexOfDefault(),
            s: 100,
            scope: 0,
            mad: 1,
            holding: 20
        }

        return (
            <Modal width={900} title="Add New Scenario"
                   visible={this.props.modalState}
                   okText="Add"
                   cancelText="Cancel"
                   okButtonProps={{form:'iterationsForm', key: 'submit', htmlType: 'submit'}}
                   onCancel={this.handleCancel}>
                <Form
                    onFinish={this.onFinish}
                    initialValues={initialValues}
                    id="iterationsForm"
                >
                    <Form.Item
                        label="Scenario Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the scenario name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Scope"
                        name="scope"

                        rules={[
                            {
                                required: true,
                                message: 'Please input the Scope!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select scope for the scenario"
                        >
                            {scopeOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Service Level"
                        name="csf"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the service level!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a csf level"
                        >
                            {options}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Cost to place one order"
                        name="s"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the cost to place one order!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Mean Absolute Deviation"
                        name="mad"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the mean absolute deviation!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Holding Cost (%)"
                        name="holding"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the Holding Cost!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }

}

import React, {useEffect} from 'react';
import {AutoComplete, Form, Input, InputNumber, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

export function StandardForm({form, fields, onFinish, initialValues, onFieldsChange}) {

    const getInputByType = item => {
        switch (item._type) {
            case 'number': return <InputNumber style={{width: '50%'}} />
            case 'long_text': return <TextArea />
            case 'select': return       <Select
                placeholder="Select an option"
                allowClear
            >
                {item.options.map((opt, key) => <Option key={key} value={opt}>{opt}</Option>)}
            </Select>
            case 'select-object': return       <Select
                placeholder="Select an option"
                allowClear
                options={item.options}
            >
            </Select>
            case 'autocomplete': return       <AutoComplete
                options={item.options.map(o => {
                    return {
                        'value': o,
                        'label': String(o)
                    }
                })}
                filterOption={(inputValue, option) =>
                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                placeholder="Select mode"
            />
            default: return <Input />
        }
    }

    return <Form
        onFieldsChange={onFieldsChange}
            onFinish={onFinish}
            form={form}
            initialValues={{...initialValues}}
            id="editForm"
        >
            {fields.map ((v,i) => <Form.Item
                key={i}
                label={<b>{v.label}</b>}
                name={v.name}
                rules={[
                    {
                        required: v.required,
                        message: v.message,
                    },
                ]}
            >
                {getInputByType(v)}
            </Form.Item>)}
        </Form>

}

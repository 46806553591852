import React, {useEffect, useState} from "react";
import api from "../../api";
import {Button, Descriptions, message, Popover, Row, Select, Statistic, Tag} from "antd";
import {DataCard} from "../helpers/cards/Card";
import {StandardTable} from "../helpers/TablePanel";
import {AdvancedFilter} from "../helpers/AdvancedFIlter";
import {checkAnyOfPermissions} from "../../helpers";

export function ItemsListTable({projectId, activeKey}) {

    const [scopes, setScopes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10)
    const [open, setOpen] = useState(false)
    const [items, setItems] = useState([])

    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const [selectedScope, setSelectedScope] = useState('')

    const [filters, setFilters] = useState({})

    const [total, setTotal] = useState(0);

    const labels = {
        'lt_days': 'Lead Time in Days',
        'cl_name': 'Material Class',
        'description': 'Short Text',
        material: 'Material Number',
        part_number: 'Part Number',
        stock_level: 'Stock on Hand',
        unit: 'Unit',
        rop: 'Reorder Point',
        max: 'MAX',
        demand: 'Annual Demand',
        'EQ BOM Count': 'Number of BOMs',
        'Trans PA (ave)': 'Transactions per Year',
        'Trans 4Y': 'Total number of transactions',
        'PotOb': 'Is Obsolete Item',
        'Insurance': 'Is Insurance Item',
        price: 'Price per Unit',
        'Total$': 'Total Price',
        'Duplicate': 'Is Duplicate item',
        criticality: 'Highest Criticality',
        equipments: 'Number of Equipments',
        installed: 'Quantity Installed',
        pms: 'PMP\'s Associated',
        approved: 'Is Item Approved',
        appr_max: 'Approved MAX',
        appr_min: 'Approved MIN'
    }

    const getItems = (page, pageSize=size) => {
        setLoading(true)
        if (pageSize !== size) {
            page = 1
        }
        api.post(`/projects/${projectId}/materials/list?`, {pagination: {page: page, size: pageSize}, filters})
            .then(json => {
                setItems(json.data.items)
                setTotal(json.data.total)
                setPage(page)
                setSize(pageSize)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (projectId) {
            getItems(1)
            getScopes();
        }
    }, [projectId, filters])

    useEffect(() => {
        if (projectId) {
            getScopes();
        }
        if (activeKey === '2') {
            getItems(1)
        }
    }, [activeKey])

    const columns = [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'name',
            width: '20%'
        }, {
            title: 'Material Type',
            dataIndex: 'Type',
            key: 'type',
            width: '20%'
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '60%'
        }
    ]

    const getScopes = () => {
        api.get(`/projects/${projectId}/scopes`)
            .then(json => {
                let _scopes = [];
                if (isSuperAdmin) {
                    _scopes = json.data.items
                } else if (isAdmin) {
                    _scopes = json.data.items.filter(sc => sc.type === 'user-defined')
                }
                setScopes(_scopes)
            })
    }

    const onChange = (newPagination, _filters, _sorter) => {
        getItems(newPagination.current, newPagination.pageSize)
    }

    const deleteFilter = (key) => {
        let filts = {...filters}
        delete filts[key]
        setFilters(filts)
    }

    const handleSelect = scope => {
        setSelectedScope(scope)
    }

    const addToScope = () => {
        message.info(`Start adding to scope`)
        api.post(`/projects/${projectId}/scopes/add/items?id=${selectedScope}`, filters)
            .then(() => {
                message.success(`${total} items were added to the scope`)
                setOpen(false)
                setSelectedScope('')
            })
            .catch(() => message.error('Something went wrong'))
    }

    const onCancel = () => {
        setSelectedScope('')
        setOpen(false)
    }

    useEffect(() => {
        checkAnyOfPermissions(['senior-user', 'approve-user', 'insurance-admin'])
            .then(() => setIsAdmin(true))
        checkAnyOfPermissions(['superadmin'])
            .then(() => setIsSuperAdmin(true))
    }, [projectId])

    const content = <div>
        <Select showSearch={true}
                value={selectedScope}
                style={{width: 200}}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={scopes.map(v => {
                    return {
                        'label': v.name,
                        'value':v._id
                    }
                })}
                onSelect={handleSelect}>
        </Select>
        <div style={{marginTop: 10}}>
            <Button type={'primary'} style={{float: 'right'}} onClick={() => addToScope()}>Add</Button>
            <Button type={'default'} onClick={() => onCancel()}>Cancel</Button>
        </div>
    </div>

    return <DataCard>
        <Row>
            <Descriptions size={'small'} style={{width: '100%'}} bordered column={1}>
                <Descriptions.Item labelStyle={{width: '20%'}} label={'Filters'}>
                    {Object.keys(filters).map(attr => <Tag className={`${filters[attr].mode}-search`}
                                                           style={{cursor: 'pointer'}}
                                                           onClick={() => deleteFilter(attr)}>
                        [{filters[attr].mode}] {labels[attr] || attr}: <b>{filters[attr].value}</b>
                    </Tag>)}
                    <div style={{float: 'right', marginBottom: 10}}>
                        <AdvancedFilter filters={filters}
                                        labels={labels}
                                        setFilter={setFilters}
                                        projectId={projectId}/>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item labelStyle={{width: '20%'}} label={'Items in current selection'}>
                    <div style={{display: 'inline'}}>
                        <Statistic valueStyle={{fontSize: 16}}
                                   value={total}/>
                        {(isAdmin || isSuperAdmin) && <Popover open={open} onOpenChange={() => setOpen(true)} content={content} title="Select scope"
                                  trigger="click">
                            <Button onClick={() => setOpen(true)}
                                    style={{float: 'right', marginTop: -25}}
                                    size="small">Add current selection to scope</Button>
                        </Popover>}
                    </div>
                </Descriptions.Item>
            </Descriptions>
        </Row>
        <StandardTable size={'small'}
                       loading={loading}
                       style={{width: '100%'}}
                       total={total}
                       currentPage={page}
                       columns={columns}
                       scroll={500}
                       onChange={onChange}
                       pageSize={size}
                       data={items}/>
    </DataCard>
}

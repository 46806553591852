import React, {useEffect, useState} from "react";
import api from "../../api";
import {checkAnyOfPermissions, createScopeLink} from "../../helpers";
import {Button, message, Statistic} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {DataCard} from "../helpers/cards/Card";
import {StandardTable} from "../helpers/TablePanel";
import {AddScopeModal} from "./AddScopeModal";

export function ScopesTable({projectId, activeKey}) {

    const [scopes, setScopes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const [pageSize, setPageSize] = useState(10)


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [total, setTotal] = useState(0);

    const style = {
        height: '80vh', maxHeight: '80vh'
    }

    const getScopes = (page=page, size=pageSize) => {
        setLoading(true)
        if (size !== pageSize){
            page = 1
        }
        api.get(`/projects/${projectId}/scopes?page=${page}&size=${size}`)
            .then(json => {
                setScopes(json.data.items)
                setTotal(json.data.total)
                setPage(page)
                setPageSize(size)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (projectId) {
            getScopes(1)
        }
    }, [projectId, isModalVisible, activeKey])

    useEffect(() => {
        checkAnyOfPermissions(['senior-user', 'approve-user', 'insurance-admin'])
            .then(() => setIsAdmin(true))
        checkAnyOfPermissions(['superadmin'])
            .then(() => setIsSuperAdmin(true))
    }, [projectId])

    const columns = [
        {
            title: 'Scope Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, item) => <a href={createScopeLink(projectId, item._id)}>{name}</a>
        }, {
            title: 'Scope Type',
            dataIndex: 'type',
            key: 'type',
        }, {
            title: 'Scope Description',
            dataIndex: 'description',
            key: 'description',
        }, {
            title: 'Created By',
            dataIndex: 'user',
            key: 'user',
        }, {
            title: 'Items in Scope',
            dataIndex: 'quantity',
            key: 'quantity',
            render: quantity => <Statistic value={quantity} valueStyle={{fontSize: 14}} />
        }, {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (_id, item) =>(item.type === 'main-scope' ? isSuperAdmin : isAdmin) && <div>
                <DeleteOutlined onClick={() => deleteScope(_id)}/>
            </div>
        },
    ]

    const deleteScope = _id => {
        api.get(`/projects/${projectId}/scopes/delete?id=${_id}`)
            .then(() => {
                message.success(`The scope was successfully deleted`)
                getScopes(1)
            })
            .catch(() => message.error('Something went wrong during deletion! Please try again later.'))
    }

    const onChange = (newPagination, _filters, _sorter) => {
        getScopes(newPagination.current, newPagination.pageSize)
    }

    return <DataCard style={style}>
        {isAdmin && <div style={{paddingBottom: 10, float: 'right'}}>
            <Button onClick={() => setIsModalVisible(true)}>Add new scope</Button>
        </div>}
        <StandardTable size={'small'}
                       loading={loading}
                       style={{width: '100%'}}
                       total={total}
                       currentPage={page}
                       columns={columns}
                       scroll={500}
                       onChange={onChange}
                       pageSize={pageSize}
                       tableProps={{
                           rowClassName: (record) => record.type === 'main-scope' ? 'main-scope' : ''
                       }}
                       data={scopes}/>
        <AddScopeModal isSuperAdmin={isSuperAdmin} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}
                       projectId={projectId}/>
    </DataCard>

}
